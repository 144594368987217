<template>
  <div>
    <div v-if="!isBaiDu">
      <van-overlay
        :show="show"
        @click="closeVideo"
        duration="0.1"
        class="wrapper"
      >
        <div @click.stop>
          <div>
            <div id="test"></div>
          </div>
        </div>
      </van-overlay>
    </div>
    <div v-else>
      <div @click.stop>
        <div>
          <div id="test"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const md5 = require("md5");
export default {
  name: "home",
  data() {
    return {
      idx: 0,
      player: "",
      test: "",
      md5,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    ccVideoId: {
      type: String,
    },
    isBaiDu: {
      type: Boolean,
    },
  },
  mounted() {
    if (window.ccH5PlayerJsLoaded) {
      this.constructor();
    }
  },
  methods: {
    closeVideo() {
      this.player.pause();
      this.setPlayerCallBack();
      this.$emit("closeVideo");
    },
    constructor() {
      this.test = document.getElementById("test");
      this.player = null;
      this.createPlayer();
    },

    createPlayer() {
      if (!this.player) {
        this.player = window.createCCH5Player({
          vid: this.ccVideoId,
          siteid: "8861CB07E3522756", //FE6E68B94C044B45测试环境cc用户id
          vc: md5(this.ccVideoId),
          autoStart: true,
          width: "100vw",
          height: "100%",
          parentNode: this.test,
        });
      }
    },
    nextPlayer() {
      this.player && this.player.destroy();
      this.createPlayer();
    },
    setPlayerCallBack() {
      window.onCCH5PlayerLoaded = this.onCCH5PlayerLoaded;
      window.on_CCH5player_ready = this.onPlayerReady;
    },
    onCCH5PlayerLoaded() {
      window.ccH5PlayerJsLoaded = true;
      this.createPlayer();
    },
    onPlayerReady() {
      window.cc_js_Player.setVolume(0.3);
    },
  },
  created() {
    this.setPlayerCallBack();
  },
  destroyed() {
    this.player && this.player.destroy();
  },
};
</script>
<style scoped>
.wrapper {
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

#test {
  width: 93vw;
  margin: 0 auto;
  box-sizing: border-box;
  height: 400px;
}
::v-deep .CCH5playerContainer {
  width: 93vw !important;
}
.close-btn {
  color: #fff;
  text-align: right;
}
.btn {
  width: 100px;
  height: 36px;
  line-height: 40px;
  text-align: center;
  background: #ff6b3a;
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
  margin-top: 70px;
  cursor: pointer;
  margin-left: 20px;
}
.desc {
  padding: 0 20px;
}
.desc p {
  font-size: 14px;
  line-height: 30px;
}
.desc p > span {
  color: #ff6b3a;
}
.title {
  margin-right: 10px;
}
.tip {
  text-indent: 2em;
}
</style>
