<template>
  <div class="main-box">
    <div class="input-bg">
      <div class="title">登录即可免费查看</div>
      <div class="code-row">
        <input
          class="input"
          v-model="tel"
          placeholder="请输入手机号码"
          oninput="if(value.length > 11)value = value.slice(0, 11)"
          pattern="[0-9]*"
          type="tel"
        />
      </div>

      <div class="code-row" style="margin-bottom: 0">
        <input
          class="input"
          v-model="code"
          placeholder="请输入验证码"
          oninput="if(value.length > 6)value = value.slice(0, 6)"
          pattern="[0-9]*"
          type="tel"
        />
        <div
          :class="codeVerfiying ? 'ode sendCode-cundown' : 'sendCode'"
          @click="send"
        >
          {{ countDown }}
        </div>
      </div>
      <div class="login">
        <div class="left" @click="closePop">取消</div>
        <div class="right" @click="submit">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { sendCode, smsLogin } from "../../api/gateway";
import { fromByteArray } from "base64-js";
import MonitorKeyboard from "@/utils/monitorKeyboard.js";

export default {
  props: {
    examType: {
      type: String,
      default: "",
    },
    province: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      code: "",
      tel: "",
      countDown: "获取验证码", // 倒计时
      codeVerfiying: false, //验证码验证中
      auth_time: "",
      monitorKeyboard: null,
      keyboardShowTime: 0,
      fromByteArray,
    };
  },
  beforeDestroy() {
    clearInterval(this.auth_time);
    setTimeout(() => {
      this.codeVerfiying = false;
      this.countDown = "获取验证码";
    }, 1000);
  },
  mounted() {
    this.getKeyboardState();
  },
  // 计算属性
  computed: {
    isSend: function () {
      return this.code.length >= 6 && this.tel.length >= 11;
    },
  },
  methods: {
    // 获取键盘状态
    getKeyboardState() {
      this.monitorKeyboard = new MonitorKeyboard();
      this.monitorKeyboard.onStart();

      // 监听虚拟键盘弹出事件
      this.monitorKeyboard.onShow(() => {
        this.keyboardShowTime = +new Date();
      });

      //监听键盘收起的事件
      this.monitorKeyboard.onHidden(() => {
        this.keyboardShowTime = 0;
      });
    },
    // 关闭手机号输入弹框
    close() {
      let currentTime = +new Date();
      let timeInterval = currentTime - this.keyboardShowTime;
      // 用于解决Android快速点击输入框掉起键盘问题
      if (this.keyboardShowTime > 0 && timeInterval < 100) return;
    },
    // 手机号校验
    phoneVerify() {
      if (!this.tel) {
        Toast("请输入手机号码");
        return false;
      }
      if (
        !/^((13[0-9])|(14[5-9])|(15([0-3]|[5-9]))|(16[6-7])|(17[1-8])|(18[0-9])|(19[1|3])|(19[5|6])|(19[8|9]))\d{8}$/.test(
          this.tel
        )
      ) {
        Toast("请输入正确的手机号码");
        return false;
      }
      return true;
    },
    // 发送验证码
    async send() {
      if (this.codeVerfiying) return;
      if (!this.phoneVerify()) return;
      // 发送验证码
      let param = {
        mobile: this.tel,
        scene: "question_login",
      };
      const { code } = await sendCode(param);
      if (code != "10000") return;
      //发送验证码成功
      this.codeCountDown();
    },

    // 验证码计时
    codeCountDown() {
      this.codeVerfiying = true;
      let totalCountDown = 60;
      this.countDown = totalCountDown + "s";
      totalCountDown--;
      this.auth_time = setInterval(() => {
        this.countDown = totalCountDown-- + "s";
        if (totalCountDown < 0) {
          this.codeVerfiying = false;
          this.countDown = "获取验证码";
          clearInterval(this.auth_time);
        }
      }, 1000);
    },

    // 登陆
    async submit() {
      if (!this.phoneVerify()) {
        Toast("请输入正确手机号");
        return;
      }
      if (!this.code) {
        Toast("请填写验证码");
        return;
      }
      if (!this.isSend) {
        Toast("请检查填写内容");
        return;
      }
      // 登陆
      let param = {
        mobile: fromByteArray(new TextEncoder().encode(this.tel)),
        code: this.code,
      };
      const { code, data } = await smsLogin(param);
      if (code === "10000") {
        Toast("登录成功");
        localStorage.setItem("examToken", data.token);
        this.closePop();
      }
    },
    closePop() {
      this.tel = "";
      this.code = "";
      this.$emit("closeOverlay");
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-box {
  width: 580px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .input-bg {
    width: 100%;
    box-sizing: border-box;
    padding: 40px 40px;
    height: 546px;
    background: #ffffff;
    border-radius: 20px;
  }
  .title {
    text-align: center;
    height: 38px;
    font-size: 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 38px;
    // margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
  }
  .input {
    display: flex;
    height: 96px;
    width: 100%;
    flex: 1;
    padding: 0 28px;
    border-width: 0px;
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  input::-webkit-input-placeholder {
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  .line {
    margin-left: 20px;
    margin-right: 20px;
    height: 1px;
    background: #ececec;
    opacity: 0.69;
  }
  .code-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 96px;
    width: 100%;
    margin-bottom: 40px;
    background: #f2f2f2;
    border-radius: 150px 150px 150px 150px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    input {
      background: #f2f2f2;
    }
  }
  .sendCode,
  .sendCode-cundown {
    width: 160px;
    height: 70px;
    background: #fef0f0;
    border-radius: 150px 150px 150px 150px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #f01414;
    line-height: 70px;
    border: 1px solid #f01414;
    margin-right: 16px;
  }

  .sendCode-cundown {
    color: #f01414;
    opacity: 0.4;
  }

  .submit-btn {
    margin-top: 30px;
    margin-left: 156px;
    margin-right: 156px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    box-sizing: border-box;
    font-weight: 500;
    color: #ffffff;
    line-height: 72px;
    height: 72px;
    background: linear-gradient(90deg, #ff5151 0%, #f61313 100%);
    border-radius: 54px;
  }

  .submit-btn-unable {
    background: rgba(240, 20, 20, 0.3);
    box-shadow: inset 0px 0px 6px 0px rgba(255, 255, 255, 0.37);
  }

  .close {
    margin-top: 40px;
    width: 68px;
    height: 68px;
  }
  .login {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    // padding: 50px 40px;
    margin-top: 60px;

    .right,
    .left {
      width: 47%;
      height: 80px;
      border-radius: 48px 48px 48px 48px;
      border: 1px solid #f01414;
      text-align: center;
      line-height: 80px;
      font-size: 30px;
      font-weight: 500;
    }
    .left {
      color: #f01414;
    }
    .right {
      color: #ffffff;
      background: #f01414;
    }
  }
}
</style>
