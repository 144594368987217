<template>
  <div class="main">
    <!-- 翻译题/简答题/案例分析题/写作题 -->
    <div>
      <div class="title padding" style="padding-top: 20px; margin-top: 0">
        {{ question.questionTypeTitle }}
      </div>
      <div class="content">
        <div v-if="question.childQuestion && question.childQuestion.length">
          <div class="stem padding" v-html="question.stem"></div>
          <div v-for="(list, index) in question.childQuestion" :key="index">
            <div
              class="crosshead padding"
              v-if="![1, 2, 3, 5].includes(list.ptypeId)"
            >
              <div class="crosshead-stem">
                <span>({{ index + 1 }}) </span
                ><span class="stem-html" v-html="list.stem"></span>
              </div>
              <div class="crosshead-answer">
                <p class="label">参考答案：</p>
                <p
                  v-if="list.ptypeId !== 4"
                  class="answer"
                  v-html="formatAnswer(list.answer, list)"
                ></p>
                <div v-else>
                  <div
                    v-for="(answer, chiIndex) in list.answer"
                    :key="chiIndex"
                    class="answer-content"
                  >
                    <div class="label-sort">{{ chiIndex + 1 }}</div>
                    <div class="answer" v-html="answer"></div>
                  </div>
                </div>
              </div>
              <div class="flex-space-between" v-if="question.ccVideoId">
                <div class="title" style="margin-top: 0">
                  <img
                    src="@/assets/imgs/exam/icon-analysis.png"
                    class="btn-wapper_img"
                    alt="图标"
                  />视频解析
                </div>
                <div class="btn-wapper-video" @click="playVideo(list)">
                  <img
                    src="@/assets/imgs/exam/icon-video.png"
                    class="btn-wapper-video_img"
                    alt="图标"
                  />{{ list.active ? "点击收起" : "点击查看" }}
                </div>
              </div>
              <cc-video
                v-if="question.ccVideoId && show && isBaiDu && list.active"
                :isBaiDu="isBaiDu"
                @closeVideo="closeVideo"
                :show="show"
                :ccVideoId="question.ccVideoId"
              ></cc-video>
              <div v-if="list.textAnalysis">
                <div class="title">
                  <img
                    src="@/assets/imgs/exam/icon-analysis.png"
                    class="btn-wapper_img"
                    alt="图标"
                  />文字解析
                </div>
                <div class="stem" v-html="list.textAnalysis"></div>
              </div>
              <div v-if="list.audioAnalysis">
                <div class="title">
                  <img
                    src="@/assets/imgs/exam/icon-analysis.png"
                    class="btn-wapper_img"
                    alt="图标"
                  />音频解析
                </div>
                <div class="stem" v-html="list.audioAnalysis"></div>
              </div>
              <div v-if="question.source">
                <div class="title">
                  <img
                    src="@/assets/imgs/exam/icon-analysis.png"
                    class="btn-wapper_img"
                    alt="图标"
                  />试题来源
                </div>
                <div class="stem" v-html="question.source"></div>
              </div>
            </div>
            <!-- 单选题 -->
            <div class="crosshead padding" v-else>
              <div class="crosshead-stem">
                <span>({{ index + 1 }}) </span
                ><span class="stem-html" v-html="list.stem"></span>
              </div>
              <!-- 选项 -->
              <div style="height: 26px"></div>
              <div
                class="li flex-row"
                :class="list.answer.includes(k.toString()) ? 'active' : ''"
                v-for="(item, k) in list.metas"
                :key="k"
              >
                <div
                  class="li-wrapper"
                  :style="{ 'border-radius': borderRadius(list) }"
                >
                  <span class="text_label">{{
                    String.fromCharCode(65 + k)
                  }}</span>
                </div>
                <span class="text_content" v-html="item"></span>
              </div>

              <div class="gap-min"></div>
              <div class="text-wrapper_7 flex-row">
                <div class="text_12">
                  参考答案：<span>{{ formatAnswer(list.answer, list) }}</span>
                </div>
                <div class="text_13">你的答案：-</div>
              </div>
              <!-- 答案 -->
              <div class="flex-space-between" v-if="question.ccVideoId">
                <div class="play-title" style="margin-top: 0">
                  <img
                    src="@/assets/imgs/exam/icon-analysis.png"
                    class="btn-wapper_img"
                    alt="图标"
                  />视频解析
                </div>
                <div class="btn-wapper-video" @click="playVideo(list)">
                  <img
                    src="@/assets/imgs/exam/icon-video.png"
                    class="btn-wapper-video_img"
                    alt="图标"
                  />
                  {{ list.active ? "点击收起" : "点击查看" }}
                </div>
              </div>
              <cc-video
                v-if="question.ccVideoId && show && isBaiDu && list.active"
                :isBaiDu="isBaiDu"
                @closeVideo="closeVideo"
                :show="show"
                :ccVideoId="question.ccVideoId"
              ></cc-video>
              <div v-if="list.textAnalysis">
                <div class="title">
                  <img
                    src="@/assets/imgs/exam/icon-analysis.png"
                    class="btn-wapper_img"
                    alt="图标"
                  />文字解析
                </div>
                <div class="stem" v-html="list.textAnalysis"></div>
              </div>
              <div v-if="list.audioAnalysis">
                <div class="title">
                  <img
                    src="@/assets/imgs/exam/icon-analysis.png"
                    class="btn-wapper_img"
                    alt="图标"
                  />音频解析
                </div>
                <div class="stem" v-html="list.audioAnalysis"></div>
              </div>
              <div v-if="question.source">
                <div class="title">
                  <img
                    src="@/assets/imgs/exam/icon-analysis.png"
                    class="btn-wapper_img"
                    alt="图标"
                  />试题来源
                </div>
                <div class="stem" v-html="question.source"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- 主观题 -->

        <div v-else>
          <div
            class="crosshead padding"
            v-if="![1, 2, 3, 5].includes(question.ptypeId)"
          >
            <div class="crosshead-stem">
              <span class="stem-html" v-html="question.stem"></span>
            </div>
            <div class="crosshead-answer">
              <p class="label">参考答案：</p>
              <p
                v-if="question.ptypeId !== 4"
                class="answer"
                v-html="formatAnswer(question.answer, question)"
              ></p>
              <div v-else>
                <div
                  v-for="(answer, index) in question.answer"
                  :key="index"
                  class="answer-content"
                >
                  <div class="label-sort">{{ index + 1 }}</div>
                  <div class="answer" v-html="answer"></div>
                </div>
              </div>
            </div>
            <div class="flex-space-between" v-if="question.ccVideoId">
              <div class="title" style="margin-top: 0">
                <img
                  src="@/assets/imgs/exam/icon-analysis.png"
                  class="btn-wapper_img"
                  alt="图标"
                />视频解析
              </div>
              <div class="btn-wapper-video" @click="playVideo(question)">
                <img
                  src="@/assets/imgs/exam/icon-video.png"
                  class="btn-wapper-video_img"
                  alt="图标"
                />
                {{ question.active ? "点击收起" : "点击查看" }}
              </div>
            </div>
            <cc-video
              v-if="question.ccVideoId && show && isBaiDu && question.active"
              :isBaiDu="isBaiDu"
              @closeVideo="closeVideo"
              :show="show"
              :ccVideoId="question.ccVideoId"
            ></cc-video>
            <div v-if="question.textAnalysis">
              <div class="title">
                <img
                  src="@/assets/imgs/exam/icon-analysis.png"
                  class="btn-wapper_img"
                  alt="图标"
                />文字解析
              </div>
              <div class="stem" v-html="question.textAnalysis"></div>
            </div>
            <div v-if="question.audioAnalysis">
              <div class="title">
                <img
                  src="@/assets/imgs/exam/icon-analysis.png"
                  class="btn-wapper_img"
                  alt="图标"
                />音频解析
              </div>
              <div class="stem" v-html="question.audioAnalysis"></div>
            </div>
            <div v-if="question.source">
              <div class="title">
                <img
                  src="@/assets/imgs/exam/icon-analysis.png"
                  class="btn-wapper_img"
                  alt="图标"
                />试题来源
              </div>
              <div class="stem" v-html="question.source"></div>
            </div>
          </div>
          <!-- 单选题 -->
          <div class="crosshead padding" v-else>
            <div
              class="crosshead-stem"
              style="border: 0"
              v-html="question.stem"
            ></div>
            <div style="height: 26px"></div>
            <!-- 选项 -->
            <div
              class="li flex-row"
              :class="question.answer.includes(k.toString()) ? 'active' : ''"
              v-for="(item, k) in question.metas"
              :key="k"
            >
              <div
                class="li-wrapper flex-col"
                :style="{ 'border-radius': borderRadius(question) }"
              >
                <span class="text_label">{{
                  String.fromCharCode(65 + k)
                }}</span>
              </div>
              <span class="text_content" v-html="item"></span>
            </div>

            <div class="gap-min"></div>
            <div class="text-wrapper_7 flex-row">
              <div class="text_12">
                参考答案：<span>{{
                  formatAnswer(question.answer, question)
                }}</span>
              </div>
              <div class="text_13">你的答案：-</div>
            </div>
            <!-- 答案 -->
            <div class="flex-space-between" v-if="question.ccVideoId">
              <div class="play-title" style="margin-top: 0">
                <img
                  src="@/assets/imgs/exam/icon-analysis.png"
                  class="btn-wapper_img"
                  alt="图标"
                />视频解析
              </div>
              <div class="btn-wapper-video" @click="playVideo(question)">
                <img
                  src="@/assets/imgs/exam/icon-video.png"
                  class="btn-wapper-video_img"
                  alt="图标"
                />
                {{ question.active ? "点击收起" : "点击查看" }}
              </div>
            </div>
            <cc-video
              v-if="question.ccVideoId && show && isBaiDu && question.active"
              :isBaiDu="isBaiDu"
              @closeVideo="closeVideo"
              :show="show"
              :ccVideoId="question.ccVideoId"
            ></cc-video>
            <div v-if="question.textAnalysis">
              <div class="title">
                <img
                  src="@/assets/imgs/exam/icon-analysis.png"
                  class="btn-wapper_img"
                  alt="图标"
                />文字解析
              </div>
              <div class="stem" v-html="question.textAnalysis"></div>
            </div>
            <div v-if="question.audioAnalysis">
              <div class="title">
                <img
                  src="@/assets/imgs/exam/icon-analysis.png"
                  class="btn-wapper_img"
                  alt="图标"
                />音频解析
              </div>
              <div class="stem" v-html="question.audioAnalysis"></div>
            </div>
            <div v-if="question.source">
              <div class="title">
                <img
                  src="@/assets/imgs/exam/icon-analysis.png"
                  class="btn-wapper_img"
                  alt="图标"
                />试题来源
              </div>
              <div class="stem" v-html="question.source"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed">
      <div class="box_3 flex-row justify-between" v-if="isWeixin && isMobile">
        <!-- 微信内打开 -->
        <div class="text-wrapper_btn flex-col">
          <span class="text_9">更多试题在线刷</span>
          <wx-open-launch-weapp
            id="launch-btn"
            appid="wxe255b48b99104194"
            username="gh_bac2ee833927"
            :path="path"
            :env-version="envVersion"
            @launch="handleLaunchFn"
            @error="handleErrorFn"
            class="launchBtn"
          >
            <script v-is="'script'" type="text/wxtag-template">
                                 <style>
                         .btn {
                 background: transparent;
                 height: 100%;
              }
                     </style>
              <div class="btn">
                 <br><br>
              </div>
            </script>
          </wx-open-launch-weapp>
        </div>
        <div class="text-wrapper_8 flex-col">
          <span class="text_10">图书教材低价购</span>
          <open-mini-program></open-mini-program>
        </div>
      </div>
      <div class="box_3 flex-row justify-between" v-else>
        <div class="text-wrapper_btn flex-col" @click="getUrlScheme('exam')">
          <span class="text_9">更多试题在线刷</span>
        </div>
        <div class="text-wrapper_8 flex-col" @click="getUrlScheme('book')">
          <span class="text_10">图书教材低价购</span>
        </div>
      </div>
    </div>

    <van-overlay :show="isLogin" @click="isLogin = false" duration="0.1">
      <div class="wrapper" @click.stop>
        <inputPhone @closeOverlay="closeOverlay"></inputPhone>
      </div>
    </van-overlay>

    <cc-video
      v-if="question.ccVideoId && show && !isBaiDu"
      :isBaiDu="isBaiDu"
      @closeVideo="closeVideo"
      :show="show"
      :ccVideoId="question.ccVideoId"
    ></cc-video>
  </div>
</template>
<script>
const AUDIOREG =
  /(<p[^>]*class="audio"[^>]*>|<p[^>]*class='audio'[^>]*>)(.*?)<\/p>/g;
import ccVideo from "./ccVideo.vue";
import inputPhone from "./inputPhone.vue";
import { getQuestionInfo } from "../../api/gateway";
import { getUrlScheme } from "@/api/test/index";
import OpenMiniProgram from "./open-miniprogram.vue";
import { getWxSign } from "@/api/test/index";
var wx = require("weixin-js-sdk");
import { isWeixin, isMobile, isOpenMiniProgram } from "@/utils/utils.js";
import { getAudioPause } from "@/utils/audio.js";
export default {
  components: {
    ccVideo,
    inputPhone,
    OpenMiniProgram,
  },
  data() {
    return {
      //题目
      titleList: [
        "",
        "单选题",
        "多选题",
        "不定项",
        "填空题",
        "判断题",
        "问答题",
        "材料题",
      ],
      show: false,
      isLogin: false,
      question: {},
      infor: {},
      isOpenMiniProgram,
      isWeixin: isWeixin(),
      isMobile: isMobile(),
      envVersion: "release",
      urlScheme: "",
      path: "/pages/index/index",
      isBaiDu: false,
    };
  },

  mounted() {
    this.getQuestionInfo();
  },
  beforeDestroy() {
    this.pauseOtherPlayers();
  },
  methods: {
    // 微信外获取Scheme
    async getUrlScheme(type) {
      if (!isOpenMiniProgram(type)) {
        return;
      }
      this.urlScheme = "";
      const params = {
        path: this.path,
        type: type === "exam" ? 3 : undefined, // 如果是 "exam" 类型，设置 type 为 3；否则不设置 type 属性
      };
      const { openLink } = await getUrlScheme(params);
      this.urlScheme = openLink;
      this.buttonClick(type);
    },
    borderRadius(list) {
      switch (list.ptypeId) {
        case 1:
        case 5:
          return "50%";
        case 2:
        case 3:
          return "4px";
        default:
          return "";
      }
    },
    // 获取试题信息
    async getQuestionInfo() {
      const url = window.location.href; // 获取当前url
      const dataArr = url.split("?")[1];
      let id = dataArr.split("&")[0].split("=")[1];

      // 获取试题信息
      let param = {
        questionId: id,
      };

      const { code, data } = await getQuestionInfo(param);
      if (code != "10000") return;

      let userAgent = navigator.userAgent;

      // 判断是否在百度App中打开
      if (
        userAgent.indexOf("baiduboxapp") > -1 ||
        userAgent.indexOf("baidubrowser") > -1
      ) {
        if (data.ptypeId === 7 && data.childQuestion.length) {
          data.childQuestion.forEach((list) => {
            list.active = false;
          });
        } else {
          data.active = false;
        }
        this.question = this.handleAnalysisData(data);
        this.isBaiDu = true; // 是百度App打开
      } else {
        data.active = false;
        this.question = this.handleAnalysisData(data);
        this.isBaiDu = false; // 非百度App打开
        // 执行其他逻辑
      }
      this.$nextTick(() => {
        // audio标签绑定监听事件
        getAudioPause();
      });

      // 微信内
      if (isWeixin()) {
        this.getSign();
        wx.ready(function () {
          let btn = document.getElementById("launch-btn");
          btn.addEventListener("launch", function () {});
          //// config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后
        });

        wx.error(function (res) {
          console.log(res, "失败信息");
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
      }
    },
    // 解析答案
    formatAnswer(arr = [], list) {
      if (arr.length === 0) return "未作答";
      if (list.ptypeId === 5) {
        return arr[0] === "1" ? "正确" : "错误";
      }
      if (list.ptypeId >= 1 && list.ptypeId <= 3) {
        return arr.map((item) => this.formatKey(item)).join();
      } else if (list.ptypeId === 4) {
        return arr;
      } else {
        return arr.join();
      }
    },
    //  播放视频
    playVideo(question) {
      if (localStorage.getItem("examToken")) {
        // examToken 存在
        if (this.isBaiDu) {
          if (this.question.ptypeId != 7) {
            this.question.active = !this.question.active;
          } else {
            if (question.active) {
              question.active = false;
            } else {
              this.question.childQuestion.forEach((list) => {
                list.active = list.id === question.id;
              });
            }
          }
        }
        this.show = true; // 展示视频
      } else {
        // examToken 不存在
        this.isLogin = true; // 展示登录表单
      }
    },
    // 选项
    formatKey(idx) {
      // 数字转英文字母
      const index = Number(idx);
      return String.fromCharCode(65 + index);
    },

    // 按钮点击事件
    buttonClick() {
      window.open(this.urlScheme);
    },
    closeVideo() {
      this.show = false;
    },
    closeOverlay() {
      this.isLogin = false;
    },
    async getSign() {
      const res = await getWxSign({
        url: encodeURIComponent(location.href.split("#")[0]),
      });
      if (res) {
        this.infor = res;
        this.initWeChat();
      }
    },

    // 注入config
    initWeChat() {
      wx.config({
        debug: false,
        appId: this.infor.appId,
        nonceStr: this.infor.nonceStr, // 必填，生成签名的随机串
        signature: this.infor.signature, // 必填，签名
        timestamp: this.infor.timestamp, // 必填，生成签名的时间戳
        jsApiList: ["chooseImage", "previewImage"],
        openTagList: ["wx-open-launch-weapp"], // 微信打开小程序开放标签
      });
    },
    handleLaunchFn() {},
    handleErrorFn() {},
    // 处理解析数据，将文字与视频拆分开来单独渲染
    handleAnalysisData(data) {
      const compiledAudioReg = new RegExp(AUDIOREG);
      if (data.analysis) {
        data.textAnalysis = data.analysis.replace(compiledAudioReg, "");
        data.audioAnalysis = data.analysis.match(compiledAudioReg)
          ? data.analysis.match(AUDIOREG)[0]
          : "";
        return data;
      }
      if (data.children && data.children.length) {
        return data.children.some(this.handleAnalysisData);
      }
      data.textAnalysis = "";
      data.audioAnalysis = "";
      return data;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .van-overlay {
  z-index: 1000;
}
.launchBtn {
  z-index: 10000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
.padding {
  padding: 0 28px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.gap-min {
  margin-top: 55px;
  height: 1px;
  background: #f7f7f7;
  margin-bottom: 50px;
}
.play-title {
  display: flex;

  img {
    width: 36px;
    height: 36px;
    margin-right: 20px;
  }
  padding-top: 0 !important;
}
.fixed {
  height: 180px;
  width: 750px;
  margin: 0 auto;
  position: fixed;
  padding: 28px;
  box-sizing: border-box;
  background: #ffffff;
  bottom: 0;
  z-index: 98;
  .box_3 {
    height: 180px;
    box-sizing: border-box;
    display: flex;
  }
  .text-wrapper_btn,
  .text-wrapper_8 {
    background-color: rgba(254, 240, 240, 1);
    border-radius: 150px;
    height: 86px;
    line-height: 86px;
    width: 48%;
    text-align: center;
    // margin-right: 22px;
    position: relative;
  }
  .text_9,
  .text_10 {
    height: 32px;
    overflow-wrap: break-word;
    color: rgba(240, 20, 20, 1);
    font-size: 32px;
    line-height: 32px;
  }
  .text-wrapper_8 {
    font-weight: 500;
    background-image: linear-gradient(
      91deg,
      rgba(247, 48, 42, 1) 0,
      rgba(255, 107, 102, 1) 100%
    );
  }
  .text_10 {
    color: rgba(255, 255, 255, 1);
  }
}

.li {
  background-color: rgba(245, 246, 249, 1);
  border-radius: 16px;
  width: 100%;
  min-height: 112px;
  display: flex;
  padding: 30px 24px;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 32px;

  .li-wrapper {
    background-color: rgba(236, 236, 236, 1);
    // border-radius: 50%;
    height: 52px;
    width: 52px;
    position: relative;
    // margin: 30px 0 0 24px;
  }
  .text_label {
    width: 52px;
    line-height: 52px;
    display: inline-block;
    overflow-wrap: break-word;
    color: rgba(102, 102, 102, 1);
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
  }
  .text_content {
    width: calc(100% - 52px);
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 32px;
    text-align: left;
    line-height: 50px;
    margin: 0 0 0 24px;
    ::v-deep p {
      margin: 0;
    }
    ::v-deep img {
      width: 100%;
    }
  }
}
.open-miniPro {
  width: 100%;
  height: 100%;
}
.text-wrapper_7 {
  background-color: rgba(255, 249, 236, 1);
  border-radius: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.text_12 {
  width: 50%;
  height: 32px;
  color: rgba(51, 51, 51, 1);
  font-size: 28px;
  text-align: center;
  line-height: 32px;
  margin: 44px 0 0 0;
  span {
    color: #05c794;
  }
}
.text_13 {
  width: 50%;
  height: 32px;
  color: rgba(51, 51, 51, 1);
  font-size: 28px;
  line-height: 32px;
  margin: 44px 0 0 0;
}
.main {
  width: 750px;
  height: 100%;
  margin: 0 auto;
  background: linear-gradient(180deg, #fef0f0 0%, #ffffff 100%) no-repeat;
  background-size: 100% 117px;
  font-size: 32px;
  text-align: left;
  line-height: 65px;
  padding-bottom: 180px;
  color: #333333 !important;
  .title,
  .play-title {
    margin-top: 25px;
    display: flex;
    align-items: center;
    font-size: 36px;
    text-align: left;
    font-weight: 500;
    color: #333333;
    img {
      width: 36px;
      height: 36px;
      margin-right: 20px;
    }
  }
  .content {
    padding-bottom: 20px;
    .tag {
      line-height: 42px;
      display: inline-block;
      padding: 0 8px;
      height: 40px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #ff5918;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 40px;
      color: #ff5918;
    }
    .stem {
      word-break: break-all;
      font-size: 32px;
      font-weight: 400;
      color: #333333;
      ::v-deep img {
        width: 100%;
      }
      ::v-deep p {
        margin: 0;
      }
    }
    .crosshead-stem {
      padding-bottom: 30px;
      border-bottom: 1px solid #ececec;
      word-break: break-all;
      font-size: 32px;
      margin-top: 40px;
      ::v-deep img {
        width: 100%;
      }
      ::v-deep p {
        margin: 0;
      }
      .stem-html {
        ::v-deep p {
          display: inline !important;
          margin: 0;
        }
      }
    }

    .crosshead-answer {
      background-color: rgba(255, 249, 236, 1);
      border-radius: 12px;
      width: 100%;
      margin: 50px auto 0 auto;
      padding: 20px 28px;
      box-sizing: border-box;
      word-break: break-all;
      justify-content: flex-center;
      .label {
        // width: 160px;
        height: 40px;
        color: rgba(51, 51, 51, 1);
        font-size: 32px;
        font-weight: 500;
        text-align: left;
      }

      .answer {
        width: 100%;
        color: rgba(51, 51, 51, 1);
        font-size: 32px;
        text-align: left;
        line-height: 65px;
        ::v-deep p {
          margin: 0;
        }
        ::v-deep img {
          width: 100%;
        }
      }
      .answer-content {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .label-sort {
          width: 36px;
          height: 36px;
          background: #ffecec;
          border-radius: 12px 0px 12px 0px;
          margin-right: 20px;
          text-align: center;
          font-size: 26px;
          font-weight: 400;
          color: #ff5918;
          line-height: 36px;
        }
        .answer {
          line-height: 65px !important;
          width: calc(100% - 56px) !important;
        }
      }
    }
  }
}
// 选项选中
.active {
  background: #e3f9f6;
  color: #05c794;
  .li-wrapper {
    background: #05c794;
    color: #ffffff;
  }
  .text_label {
    color: #ffffff;
  }
  .text_content {
    color: #05c794;
  }
}
// 视频播放
.btn-wapper-video {
  width: 212px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f01414;
  height: 54px;
  border-radius: 27px;
  font-size: 26px;
  font-weight: 400;
  color: #ffffff;
  .btn-wapper-video_img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
}
.flex-space-between {
  margin-top: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: space-between;
}
.justify-between {
  display: flex;
  justify-content: space-between;
}
</style>
