import { post } from "@/utils/request";

/**
 * 获取枚举值，赛选列表
 * @returns Object
 */
export const getEnumList = (data) =>
  post("/gateway/kukesystem/enum/enumList", data);

/**
 * 发送验证码
 * mobile 手机号
 * scene 场景值 
 * 库课教师
   login 登录
   reset_pass 找回密码
   logoff 注销账号
   oauth_bind_mobile 三方登录绑定手机号）
*  库课图书
    kukebook_login
 * @returns Object
 */
export const sendCode = (data) => post("/gateway/kukeopen/sms/sendCode", data);

/**
 * 获取题库试题详情
 * @returns Object
 */
export const getQuestionInfo = (data) =>
  post("/gateway/kukequestion/index/question/getQuestionInfo", data);

/**
 * 题库用户登陆
 * @returns Object
 */
export const smsLogin = (data) =>
  post("/gateway/kukeuser/index/question/user/smsLogin", data);
