// audio 播放处理，同B端
// 设置当前的audio
let currentAudio = null;

// 页面多个音频标签处理
export const playAudio = (audio) => {
  if (currentAudio !== audio) {
    // 检查当前播放的音频是否与点击的音频相同
    pauseCurrentAudio(); // 暂停当前播放的音频
    currentAudio = audio; // 更新当前播放的音频
    currentAudio.play(); // 开始播放点击的音频
  }
};

// 监听页面所有音频点击播放
// 导出一个函数，用于获取音频暂停状态
export const getAudioPause = () => {
  // 获取文档中所有的音频元素
  const audioElements = document.querySelectorAll("audio");
  // 遍历音频元素
  audioElements.forEach((v) => {
    // 为音频元素添加播放事件
    v.addEventListener(
      "play",
      () => {
        // 播放音频
        playAudio(v);
      },
      false
    );
  });
};

export const pauseCurrentAudio = () => {
  if (currentAudio !== null) {
    currentAudio.pause();
    currentAudio = null;
  }
};

// 关闭当前页面所有音频
export const closeAllAudio = () => {
  const audioElements = document.querySelectorAll("audio");
  audioElements.forEach((v) => v.pause());
};
